import { AuthErrorCodes } from "firebase/auth";

export const FIREBASE_ERROR_MESSAGES_MAP: Record<string, string> = {
  [AuthErrorCodes.USER_DELETED]:
    "Could not find account with those login credentials.",
  [AuthErrorCodes.WEAK_PASSWORD]: "Password should be at least 6 characters.",
};

export const errorTypeMessage: Record<string, string> = {
  required: "is required.",
  pattern: "is invalid.",
};

export function getFriendlyErrorMessage(errorCode: string): string {
  switch (errorCode) {
    case "auth/invalid-phone-number":
      return "The phone number entered is invalid.";
    case "auth/missing-phone-number":
      return "Please enter a phone number.";
    case "auth/invalid-verification-code":
      return "The verification code is incorrect.";
    case "auth/code-expired":
      return "The verification code has expired. Please request a new one.";
    // Add more cases as needed
    default:
      return "An unexpected error occurred. Please try again.";
  }
}
