import React, { useState } from "react";
import { User } from "firebase/auth";

import VerificationMethodSelection from "./VerificationMethodSelection";
import PhoneNumberEntry from "./PhoneNumberEntry";
import CodeEntry from "./CodeEntry";
import EmailEntry from "./EmailEntry";
import EmailSentConfirmation from "./EmailSentConfirmation";

type VerifyAccountPromptProps = {
  prompt?: string;
  phone?: string;
  email?: string;
  onStart?: () => Promise<any>;
  onComplete?: (user: User) => Promise<any>;
};

type Step =
  | "initial"
  | "sms_phone_entry"
  | "sms_code_entry"
  | "email_entry"
  | "email_code_entry";

const VerifyAccountPrompt: React.FC<VerifyAccountPromptProps> = ({
  prompt,
  phone,
  email,
  onStart,
  onComplete,
}) => {
  const [step, setStep] = useState<Step>("initial");
  const [phoneNumber, setPhoneNumber] = useState(phone || "");
  const [emailAddress, setEmailAddress] = useState(email || "");
  const [confirmationResult, setConfirmationResult] = useState<any>(null); // Store confirmationResult here
  const [startResult, setStartResult] = useState<any>(null);

  return (
    <div className="rounded-lg border border-gray-100 bg-white p-8 shadow-xl lg:p-8">
      {step === "initial" && (
        <VerificationMethodSelection
          prompt={prompt}
          onSelectMethod={async (method) => {
            if (onStart) {
              const startResponse = await onStart();
              setStartResult(startResponse);
            }

            if (method === "sms") {
              setStep("sms_phone_entry");
            } else if (method === "email") {
              setStep("email_entry");
            }
          }}
        />
      )}
      {step === "sms_phone_entry" && (
        <PhoneNumberEntry
          disableInput={!!phone}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          onNext={() => setStep("sms_code_entry")}
          onBack={() => setStep("initial")}
          setConfirmationResult={setConfirmationResult}
        />
      )}
      {step === "sms_code_entry" && (
        <CodeEntry
          phoneNumber={phoneNumber}
          confirmationResult={confirmationResult}
          onBack={() => setStep("initial")}
          onComplete={onComplete}
        />
      )}
      {step === "email_entry" && (
        <EmailEntry
          disableInput={!!email}
          emailAddress={emailAddress}
          setEmailAddress={setEmailAddress}
          onNext={() => setStep("email_code_entry")}
          onBack={() => setStep("initial")}
          actionCodeQueryParams={startResult}
        />
      )}
      {step === "email_code_entry" && (
        <EmailSentConfirmation
          emailAddress={emailAddress}
          onBack={() => setStep("initial")}
        />
      )}
    </div>
  );
};

export default VerifyAccountPrompt;
