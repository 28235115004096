import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "firebase/auth";

import { PRE_NEED_LEAD_SCHEDULE_CONFIG } from "constants/onboarding-pre-need";
import useQueryParams from "hooks/useQueryParams";
import useParallax from "hooks/useParallax";
import { fetchPublicAPI, useFetchPublicAPI } from "hooks/usePublicAPI";
import { useGetIdToken } from "hooks/useAPI";
import { OnboardingContainer } from "layouts/OnboardingLayout";
import Header from "components/Header";
import PaymentMethodForm from "components/PaymentMethodForm";
import GetInTouch from "components/GetInTouch";
import VerifyAccountPrompt from "components/VerifyAccountPrompt";
import { useFirebase } from "firebase";

function getOnboardingPolicy(policyId: string) {
  const data = window.localStorage.getItem(`onboarding_policy_${policyId}`);

  if (!data) {
    return null;
  }

  try {
    return JSON.parse(data);
  } catch (error) {
    console.log("Error parsing onboarding policy data", error);
    return null;
  }
}

function VerifyOrSign() {
  const navigate = useNavigate();
  const { user, loading } = useFirebase();
  const isAuthenticated = user && !loading;
  const [searchParamsObject] = useQueryParams();
  const getIdToken = useGetIdToken();
  const onboardingPolicy = getOnboardingPolicy(searchParamsObject.p);

  async function startVerification() {
    const claimToken = window.localStorage.getItem(
      `onboarding_policy_claim_token_${searchParamsObject.p}`,
    );

    if (claimToken) {
      return;
    }
    const response = await fetchPublicAPI(
      `/pre-need/onboarding/policies/${searchParamsObject.p}/actions/create-claim-token`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (response.policy_claim_token) {
      window.localStorage.setItem(
        `onboarding_policy_claim_token_${searchParamsObject.p}`,
        response.policy_claim_token,
      );
    }

    return response;
  }

  const completeVerification = useCallback(
    async (userResult: User) => {
      try {
        const claimToken = window.localStorage.getItem(
          `onboarding_policy_claim_token_${searchParamsObject.p}`,
        );
        if (!claimToken) {
          console.log("Claim token not available");
          return;
        }

        if (!userResult) {
          return;
        }

        const idToken = await userResult.getIdToken();

        await fetchPublicAPI(
          `/pre-need/onboarding/policies/${searchParamsObject.p}/actions/claim`,
          {
            method: "POST",
            body: JSON.stringify({
              payload: {
                token: claimToken,
                first_name: onboardingPolicy.first_name,
                last_name: onboardingPolicy.last_name,
              },
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          },
        );

        window.localStorage.removeItem(
          `onboarding_policy_claim_token_${searchParamsObject.p}`,
        );

        navigate("/");
      } catch (error) {
        console.error("Error completing verification:", error);
      }
    },
    [navigate, searchParamsObject.p, onboardingPolicy],
  );

  return (
    <div>
      {isAuthenticated ? (
        <div></div>
      ) : (
        <VerifyAccountPrompt
          prompt="So you can manage your policy."
          phone={onboardingPolicy?.phone}
          email={onboardingPolicy?.email}
          onStart={startVerification}
          onComplete={completeVerification}
        />
      )}
    </div>
  );
}

function FormSkelly() {
  return (
    <div className="flex items-center justify-center">
      <div className="w-full animate-pulse space-y-6">
        <div className="h-10 w-1/3 rounded bg-gray-200"></div>
        <div className="space-y-4">
          <div className="h-14 w-full rounded bg-gray-200"></div>
          <div className="flex space-x-2">
            <div className="h-14 w-full rounded bg-gray-200"></div>
            <div className="h-14 w-full rounded bg-gray-200"></div>
          </div>
          <div className="h-14 w-full rounded bg-gray-200"></div>
        </div>
        <div className="h-10 w-1/4 rounded bg-gray-200"></div>
      </div>
    </div>
  );
}

function IllustrationSection() {
  const { ref: birdRef, position: birdPosition } = useParallax();

  return (
    <div className="relative mx-auto w-full max-w-screen-xl">
      <picture>
        <source srcSet="/public/bird.webp" type="image/webp" />
        <img
          loading="lazy"
          src="/public/bird.png"
          className="absolute top-16 h-16 w-16 transition-transform duration-300"
          style={{ right: `${birdPosition}%` }}
          ref={birdRef}
        />
      </picture>
      <div className="h-36 w-full bg-[url('/public/sun-birds-bg.png')] bg-contain bg-top bg-no-repeat lg:bg-left"></div>
    </div>
  );
}

export default function QuoteFinalize() {
  const [searchParamsObject] = useQueryParams();
  const { data, error, isLoading } = useFetchPublicAPI<{
    status: string;
  }>({
    url: `/pre-need/onboarding/policies/${searchParamsObject.p}/status`,
    options: {
      method: "GET",
    },
  });

  if (
    !searchParamsObject.p ||
    (!isLoading &&
      !["onboarding", "pending-signatures"].includes(data?.status)) ||
    !!error
  ) {
    console.log("Policy is not in onboarding/pending-signatures status", data);
    return (
      <OnboardingContainer>
        <Header
          properties={{ call_or_schedule: PRE_NEED_LEAD_SCHEDULE_CONFIG }}
        />

        <div className="mx-auto my-16 max-w-xl px-3 sm:px-4">
          <h1 className="mb-4 text-center">Something isn’t quite right</h1>

          <div className="space-y-2">
            <p>
              It looks like we’re missing some important information to guide
              you to the right place.
            </p>
            <p>Please try clicking the link exactly as it was sent to you.</p>
            <p>
              If the problem persists, please reach out to us at{" "}
              <a
                href="mailto:preplan@after.com"
                className="underline decoration-dotted underline-offset-2"
              >
                preplan@after.com
              </a>
              .
            </p>
          </div>
        </div>
      </OnboardingContainer>
    );
  }

  return (
    <OnboardingContainer>
      <div className="w-full px-3 sm:px-4">
        <Header
          properties={{ call_or_schedule: PRE_NEED_LEAD_SCHEDULE_CONFIG }}
        />

        <div className="mx-auto my-16 max-w-xl">
          {isLoading ? (
            <FormSkelly />
          ) : (
            <div className="space-y-8 md:space-y-16 lg:space-y-20">
              <div className="mb-8 text-center">
                <h1 className="mb-6 font-serif text-2xl font-semibold xs:text-3xl sm:text-5xl">
                  Peace of mind is just a few minutes away.
                </h1>

                {data?.status === "pending-signatures" ? (
                  <>
                    <h2 className="text-xl font-semibold text-sage xs:text-2xl">
                      Your documents have been sent to you
                    </h2>
                  </>
                ) : (
                  <>
                    <h2 className="text-xl font-semibold text-sage xs:text-2xl">
                      One of our pre-planning specialists will be reaching out
                      in the next business day to finalize your policy
                    </h2>
                  </>
                )}
              </div>

              <VerifyOrSign />

              <IllustrationSection />

              <GetInTouch
                properties={{ call_or_schedule: PRE_NEED_LEAD_SCHEDULE_CONFIG }}
              />
            </div>
          )}
        </div>
      </div>
    </OnboardingContainer>
  );
}
