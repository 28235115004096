import React, { useState } from "react";
import { sendSignInLinkToEmail } from "firebase/auth";

import { getFriendlyErrorMessage } from "utils/errors";
import { useFirebase } from "firebase";

type EmailEntryProps = {
  disableInput?: boolean;
  emailAddress: string;
  setEmailAddress: (email: string) => void;
  onNext: () => void;
  onBack: () => void;
  actionCodeQueryParams?: Record<string>;
};

const EmailEntry: React.FC<EmailEntryProps> = ({
  disableInput,
  emailAddress,
  setEmailAddress,
  onNext,
  onBack,
  actionCodeQueryParams,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const { auth } = useFirebase();

  const handleSendEmailLink = async () => {
    setErrorMessage("");
    try {
      const queryParams = actionCodeQueryParams
        ? `?${new URLSearchParams(actionCodeQueryParams).toString()}`
        : "";
      const actionCodeSettings = {
        url: `https://app.after.com/email-action${queryParams}`,
        handleCodeInApp: true,
      };
      await sendSignInLinkToEmail(auth, emailAddress, actionCodeSettings);
      window.localStorage.setItem("emailForSignIn", emailAddress);
      onNext();
    } catch (error: any) {
      console.error("Error sending email link", error);
      setErrorMessage(getFriendlyErrorMessage(error.code));
    }
  };

  return (
    <div className="text-center">
      <button onClick={onBack} className="mb-4 text-blue-500 underline">
        Back
      </button>

      <h3 className="text-center font-serif text-2xl font-bold sm:text-3xl">
        Verify your email address
      </h3>

      <input
        type="email"
        className="mt-4 rounded border border-gray-300 p-2"
        placeholder="Enter email address"
        value={emailAddress}
        onChange={(e) => setEmailAddress(e.target.value)}
        disabled={disableInput}
      />

      <button
        onClick={handleSendEmailLink}
        className="ml-2 mt-4 rounded bg-cantelope p-2 font-bold text-soil"
      >
        Send Verification Email
      </button>
      {errorMessage && <p className="mt-2 text-red-500">{errorMessage}</p>}
    </div>
  );
};

export default EmailEntry;
