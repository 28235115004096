export const PRE_NEED_LEAD_SCHEDULE_CONFIG = {
  calendly: {
    after_id: "pre_need_onboarding_payment",
    event_url:
      "https://calendly.com/after-pre-planning/start-pre-planning-portal",
    analytics: {
      facebook: {
        event: "Schedule",
        data: {
          content_name: "pre-need-lead",
          content_category: "pre-need",
        },
      },
    },
  },
  phone: { after_id: "pre_need_onboarding_payment", number: "+18444150199" },
};
