import React from "react";

interface StepIndicatorProps {
  stepNumber: number;
  stepText: string;
  textColor?: string;
}

const StepIndicator = ({
  stepNumber,
  stepText,
  textColor = "white",
}: StepIndicatorProps) => {
  return (
    <p className="my-0 flex items-center space-x-2 font-serif text-lg font-semibold text-olive">
      <span className="flex h-8 w-8 items-center justify-center rounded-full bg-olive font-bold text-white">
        {stepNumber}
      </span>
      <span>{stepText}</span>
    </p>
  );
};

export default StepIndicator;
