import React from "react";
import { pick } from "lodash-es";

import Header from "components/Header";
import { useFetchPublicAPI } from "hooks/usePublicAPI";
import useQueryParams from "hooks/useQueryParams";
import Form from "components/Form";
import { FormConfig } from "components/Form/types";
import { useFormContext } from "components/Form/hooks";

function FormSkelly() {
  return (
    <div className="flex items-center justify-center">
      <div className="w-full animate-pulse space-y-6">
        <div className="h-10 w-1/3 rounded bg-gray-200"></div>
        <div className="space-y-4">
          <div className="h-14 w-full rounded bg-gray-200"></div>
          <div className="h-14 w-full rounded bg-gray-200"></div>
          <div className="h-14 w-full rounded bg-gray-200"></div>
        </div>
        <div className="h-10 w-1/4 rounded bg-gray-200"></div>
      </div>
    </div>
  );
}

function useFormMetadata() {
  const [queryParams] = useQueryParams();

  const PARAMS = [
    "utm_medium",
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ];

  return pick(queryParams, PARAMS);
}

type FormLoaderProps = {
  responseId: string;
  formId: string;
};

const FormLoader: React.FC<FormLoaderProps> = ({ formId, responseId }) => {
  const metadata = useFormMetadata();
  const context = useFormContext();
  const { data, error, isLoading } = useFetchPublicAPI<{
    form_config: FormConfig;
    form_context: Record<string, unknown>;
  }>({
    url: `/forms/${formId}/${responseId}`,
    options: {
      method: "POST",
      headers: { "content-type": "application/json" },
    },
    data: {
      metadata,
      context,
    },
  });

  if (isLoading) {
    return (
      <div>
        <Header />

        <div className="mx-auto my-16 max-w-xl">
          <FormSkelly />
        </div>
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className="flex items-center justify-center text-center">
        <div>
          <h1 className="mb-2 font-serif">
            Sorry, we could not find that form
          </h1>
          <p>
            Please reach out to us at{" "}
            <a
              href="mailto:support@after.com"
              target="_blank"
              className="underline decoration-dotted underline-offset-4 hover:decoration-solid"
            >
              support@after.com
            </a>{" "}
            if you need help.
          </p>
        </div>
      </div>
    );
  }

  return (
    <Form
      formConfig={data.form_config}
      formContext={data.form_context}
      metadata={metadata}
      responseId={responseId}
    />
  );
};

export default FormLoader;
