import React from "react";

type EmailSentConfirmationProps = {
  emailAddress: string;
  onBack: () => void;
};

const EmailSentConfirmation: React.FC<EmailSentConfirmationProps> = ({
  emailAddress,
  onBack,
}) => {
  return (
    <div className="text-center">
      <button onClick={onBack} className="mb-4 text-blue-500 underline">
        Back
      </button>
      <h3 className="text-center font-serif text-2xl font-bold sm:text-3xl">
        Check your email
      </h3>
      <p>
        We have sent a verification link to your email address: {emailAddress}
      </p>
    </div>
  );
};

export default EmailSentConfirmation;
