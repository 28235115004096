import Stars from "svg/stars.svg";

type Review = {
  user_name: string;
  user_photo_url: string;
  text: string;
};

const REVIEWS: Array<Review> = [
  {
    user_name: "Barbara R.",
    user_photo_url: "",
    text: "I was pleasantly surprised by the transparent and honest service Zane provided. I helped in planning my mother's funeral and the upsell was horrific. This was nothing like that. Although I am preplanning for my husband, I am very happy with After.com.",
  },
  {
    user_name: "Richard W.",
    user_photo_url: "",
    text: "The simplicity of the process is number one. Cut and dry with more than reasonable costs. Jonah answered every question I had and left communication open for future need. If anyone wants this route to go in the cremation process I highly recommend After.com.",
  },
  {
    user_name: "Sandy G.",
    user_photo_url: "",
    text: "The plans are very inexpensive and it's a great peace of mind knowing the family is not going to have to make those kinds of arrangements. They're very friendly and helpful on the phone and the signing up process is very simple!",
  },
  {
    user_name: "Kathy",
    user_photo_url: "",
    text: "The process was so easy and the representative was knowledgeable, patient and kind. Definitely would recommend this company to everyone.",
  },
];

export default function Reviews({ max }: { max?: number }) {
  return (
    <div className="grid w-full gap-4 sm:gap-6">
      {REVIEWS.slice(0, max).map((review) => (
        <div className="rounded-xl bg-ivory p-4" key={review.user_name}>
          <p>{review.text}</p>

          <div className="mt-3 flex items-center justify-between">
            <span className="font-serif">{review.user_name}</span>

            <Stars className="w-24" />
          </div>
        </div>
      ))}

      <div className="mb-2 mt-1 text-center">
        <a
          href="https://www.after.com/reviews"
          target="_blank"
          className="text-soil underline"
        >
          Read more reviews
        </a>
      </div>
    </div>
  );
}
