import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

type AccordionProps = {
  title: string;
  items: Array<Item>;
};

type Item = {
  summary: string;
  details: string;
};

const Accordion = ({ title, items }: AccordionProps) => {
  return (
    <div className="w-full bg-white">
      <div className="mx-auto py-4">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-center font-serif text-2xl font-bold sm:text-4xl">
            {title}
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {items.map((item) => (
              <Disclosure as="div" key={item.summary} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <DisclosureButton className="flex w-full items-start justify-between px-4 text-left text-gray-900">
                        <span className="text-left text-base font-semibold leading-7 text-soil">
                          {item.summary}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusIcon
                              className="h-6 w-6 text-soil"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusIcon
                              className="h-6 w-6 text-soil"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </DisclosureButton>
                    </dt>
                    <DisclosurePanel as="dd" className="mt-2 px-4 pr-12">
                      <p className="text-left text-base leading-7 text-gray-600">
                        {item.details}
                      </p>
                    </DisclosurePanel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
