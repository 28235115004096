import _ from "lodash";

import { FormConfig, Answer } from "./types";

export const buildAnswer = (
  formData: Record<string, any>,
  fieldRef: string,
  formConfig: FormConfig,
): Answer | null => {
  const field = formConfig.fields.find((f) => f.ref === fieldRef);

  if (!field) {
    return null;
  }

  const value = formData[fieldRef];

  if (value === undefined || value === null) {
    return null;
  }

  // Map field types to answer types
  switch (field.type) {
    case "short_text":
    case "long_text":
    case "email":
    case "phone_number":
    case "date":
      return {
        type: "text",
        field: {
          id: field.id,
          type: field.type,
          ref: field.ref,
        },
        text: value.trim(),
      };
    case "number":
      return {
        type: "number",
        field: {
          id: field.id,
          type: field.type,
          ref: field.ref,
        },
        number: Number(value),
      };
    case "yes_no":
      return {
        type: "boolean",
        field: {
          id: field.id,
          type: field.type,
          ref: field.ref,
        },
        boolean: value === "true" || value === true,
      };
    case "choices":
      if (Array.isArray(value)) {
        return {
          type: "choices",
          field: {
            id: field.id,
            type: field.type,
            ref: field.ref,
          },
          choices: {
            labels: value,
          },
        };
      } else {
        return {
          type: "choice",
          field: {
            id: field.id,
            type: field.type,
            ref: field.ref,
          },
          choice: {
            label: value,
          },
        };
      }
    case "full_name":
      if (typeof value === "object") {
        return {
          type: "full_name",
          field: {
            id: field.id,
            type: field.type,
            ref: field.ref,
          },
          full_name: {
            first_name: _.get(value, "first_name", "").trim(),
            middle_name: _.get(value, "middle_name", "").trim(),
            last_name: _.get(value, "last_name", "").trim(),
            suffix: _.get(value, "suffix", "").trim(),
          },
        };
      }
      return null;

    case "location":
      if (typeof value === "object") {
        return {
          type: "location",
          field: {
            id: field.id,
            type: field.type,
            ref: field.ref,
          },
          location: {
            street: _.get(value, "street", "").trim(),
            address_line_1: _.get(value, "address_line_1", "").trim(),
            address_line_2: _.get(value, "address_line_2", "").trim(),
            city: _.get(value, "city", "").trim(),
            state: _.get(value, "state", "").trim(),
            zip: _.get(value, "zip", "").trim(),
            country: _.get(value, "country", "").trim(),
          },
        };
      }
      return null;
    // Handle other field types as needed
    default:
      return {
        type: "text",
        field: {
          id: field.id,
          type: field.type,
          ref: field.ref,
        },
        text: value.trim(),
      };
  }
};

export const buildFormResponseData = (
  data: Record<string, any>,
  formConfig: FormConfig,
) => {
  const answers: Answer[] = Object.keys(data)
    .map((key) => {
      return buildAnswer(data, key, formConfig);
    })
    .filter((answer): answer is Answer => answer !== null);

  const formResponseData = {
    answers,
  };

  return formResponseData;
};
