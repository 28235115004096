import React from "react";
import { FieldError } from "react-aria-components";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

type FieldWarningProps = {
  children: React.ReactNode;
};

const FieldWarning: React.FC<FieldWarningProps> = ({ children }) => (
  <FieldError className="mt-2 block rounded bg-red-200 p-2 text-sm text-red-700">
    <ExclamationCircleIcon className="mr-1 inline-block h-5 w-5" />
    {children}
  </FieldError>
);

export default FieldWarning;
