import React, { createContext, useContext, useEffect, useState } from "react";
import type { FirebaseApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signOut, User } from "firebase/auth";
import type { ReactNode } from "react";

export interface FirebaseContextType {
  firebaseApp: FirebaseApp;
  auth: ReturnType<typeof getAuth>;
  user: User | null;
  loading: boolean;
  logout: () => Promise<void>;
}

export const FirebaseContext = createContext<FirebaseContextType | undefined>(
  undefined,
);

export const FirebaseProvider = ({
  children,
  app,
}: {
  children: ReactNode;
  app: FirebaseApp;
}) => {
  const auth = getAuth(app);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      setUser(firebaseUser);
      setLoading(false);
    });
    return unsubscribe;
  }, [auth]);

  const logout = async () => {
    try {
      await signOut(auth);
      // Optional: Perform any additional state updates or cleanup here
    } catch (error) {
      console.error("Error signing out:", error);
      // Handle error appropriately
    }
  };

  return (
    <FirebaseContext.Provider value={{ app, auth, user, loading, logout }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = (): FirebaseContextType => {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error("useFirebase must be used within a FirebaseProvider");
  }
  return context;
};
