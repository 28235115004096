import LogoText from "svg/logo-type.svg";
import Phone from "svg/phone.svg";

export default function Footer() {
  return (
    <footer className="a-footer container mx-auto flex flex-col items-center justify-center pb-8 text-center">
      <hr className="mb-4 w-10/12 sm:w-full" />

      <div className="py-5">
        <LogoText height="1.25rem" />
      </div>

      <small>
        Copyright &copy; {new Date().getFullYear()} · After Services, Inc. ·{" "}
        {__RELEASE__}
      </small>
    </footer>
  );
}
