import React from "react";

import Logo from "svg/logo-with-type.svg";
import CallOrSchedule, {
  CallOrScheduleDetails,
} from "components/CallOrSchedule";
import UserNav from "./UserNav";

type HeaderProps = {
  properties?: {
    call_or_schedule?: CallOrScheduleDetails;
  };
};

const Header: React.FC<HeaderProps> = ({ properties }) => {
  return (
    <header className="flex h-16 items-center justify-center">
      <div className="flex w-full max-w-3xl grid-cols-2 items-center justify-between sm:grid sm:grid-cols-3">
        <div className="flex justify-start">
          <UserNav />
        </div>

        <div className="col-start-auto flex justify-center px-4 sm:col-start-2 sm:px-0">
          <Logo className="w-24 2xs:w-28" />
        </div>

        <div className="flex justify-end">
          {properties?.call_or_schedule && (
            <CallOrSchedule details={properties.call_or_schedule} />
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
