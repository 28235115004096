import React, { useState } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import { getFriendlyErrorMessage } from "utils/errors";
import { useFirebase } from "firebase"; // Use the custom hook

type PhoneNumberEntryProps = {
  disableInput?: boolean;
  phoneNumber: string;
  setPhoneNumber: (phone: string) => void;
  onNext: () => void;
  onBack: () => void;
  setConfirmationResult: (result: any) => void; // New prop
};

const PhoneNumberEntry: React.FC<PhoneNumberEntryProps> = ({
  disableInput,
  phoneNumber,
  setPhoneNumber,
  onNext,
  onBack,
  setConfirmationResult,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const { auth } = useFirebase(); // Use the custom hook

  const handleSendCode = async () => {
    setErrorMessage("");
    const appVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      },
      auth,
    );

    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        appVerifier,
      );

      // Store confirmationResult in state
      setConfirmationResult(confirmationResult);
      // Remove reCAPTCHA widget from the page
      appVerifier.clear();

      onNext();
    } catch (error: any) {
      console.error("Error during signInWithPhoneNumber", error);
      setErrorMessage(getFriendlyErrorMessage(error.code));
      appVerifier.recaptcha?.reset();
      // Handle reCAPTCHA cleanup if needed
    }
  };

  return (
    <div className="text-center">
      <button onClick={onBack} className="mb-4 text-blue-500 underline">
        Back
      </button>

      <h3 className="text-center font-serif text-2xl font-bold sm:text-3xl">
        Verify your phone number
      </h3>
      <input
        type="text"
        className="mt-4 rounded border border-gray-300 p-2"
        placeholder="Enter phone number"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        disabled={disableInput}
      />
      <button
        onClick={handleSendCode}
        className="ml-2 mt-4 rounded bg-cantelope p-2 font-bold text-soil"
      >
        Send Code
      </button>
      {errorMessage && <p className="mt-2 text-red-500">{errorMessage}</p>}
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default PhoneNumberEntry;
