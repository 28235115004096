import {
  FormConfig,
  LogicJump,
  LogicJumpCondition,
  LogicJumpAction,
} from "./types";

// Move evaluate functions outside the component to reuse them
export function evaluateCondition(
  condition: LogicJumpCondition,
  getValues: () => Record<string, any>,
  variables: Record<string, any>,
  context: Record<string, any>,
): boolean {
  const { op, vars } = condition;

  switch (op) {
    case "always":
      return true;

    case "and":
      return (
        vars?.every((varOrCondition) =>
          isCondition(varOrCondition)
            ? evaluateCondition(
                varOrCondition as LogicJumpCondition,
                getValues,
                variables,
                context,
              )
            : false,
        ) ?? false
      );

    case "or":
      return (
        vars?.some((varOrCondition) =>
          isCondition(varOrCondition)
            ? evaluateCondition(
                varOrCondition as LogicJumpCondition,
                getValues,
                variables,
                context,
              )
            : false,
        ) ?? false
      );

    default:
      if (vars && vars.length === 2) {
        return evaluateSimpleCondition(
          condition,
          getValues,
          variables,
          context,
        );
      }
      return false;
  }
}

function isCondition(
  varOrCondition: any,
): varOrCondition is LogicJumpCondition {
  return varOrCondition && typeof varOrCondition.op === "string";
}

function evaluateSimpleCondition(
  condition: LogicJumpCondition,
  getValues: () => Record<string, any>,
  variables: Record<string, any>,
  context: Record<string, any>,
): boolean {
  const { op, vars } = condition;
  if (!vars || vars.length !== 2) {
    return false;
  }
  const left = vars[0];
  const right = vars[1];

  const leftValue = getConditionVarValue(left, getValues, variables, context);
  const rightValue = getConditionVarValue(right, getValues, variables, context);

  switch (op) {
    case "is":
      return compareChoices(leftValue, rightValue, "is");
    case "is_not":
      return compareChoices(leftValue, rightValue, "is_not");
    case "equal":
      return leftValue === rightValue;
    case "not_equal":
      return leftValue !== rightValue;
    case "begins_with":
      return (
        typeof leftValue === "string" &&
        typeof rightValue === "string" &&
        leftValue.startsWith(rightValue)
      );
    case "ends_with":
      return (
        typeof leftValue === "string" &&
        typeof rightValue === "string" &&
        leftValue.endsWith(rightValue)
      );
    case "contains":
      return (
        typeof leftValue === "string" &&
        typeof rightValue === "string" &&
        leftValue.includes(rightValue)
      );
    case "not_contains":
      return (
        typeof leftValue === "string" &&
        typeof rightValue === "string" &&
        !leftValue.includes(rightValue)
      );
    case "greater_than":
      return Number(leftValue) > Number(rightValue);
    case "greater_equal_than":
      return Number(leftValue) >= Number(rightValue);
    case "lower_than":
      return Number(leftValue) < Number(rightValue);
    case "lower_equal_than":
      return Number(leftValue) <= Number(rightValue);
    default:
      return false;
  }
}

// Helper function to handle 'is' and 'is_not' for choices fields
function compareChoices(
  leftValue: any,
  rightValue: any,
  operator: "is" | "is_not",
): boolean {
  if (Array.isArray(leftValue)) {
    // Multiple selections
    const containsChoice = leftValue.includes(rightValue);
    return operator === "is" ? containsChoice : !containsChoice;
  } else {
    // Single selection
    const isEqual = leftValue === rightValue;
    return operator === "is" ? isEqual : !isEqual;
  }
}

export function getConditionVarValue(
  variable: any,
  getValues: () => Record<string, any>,
  variables: Record<string, any>,
  context: Record<string, any>,
): any {
  switch (variable.type) {
    case "field":
      return getValues()[variable.value];
    case "constant":
      return variable.value;
    case "choice":
      return variable.value;
    case "variable":
      return variables[variable.value];
    case "context":
      return context[variable.value];
    default:
      return null;
  }
}

// Function to get relevant field refs based on current form data
export function getRelevantFieldRefs(
  formConfig: FormConfig,
  formContext: Record<string, any>,
  formData: Record<string, any>,
): string[] {
  const visitedFields: Set<string> = new Set();
  const fieldRefs: string[] = [];

  let currentFieldRef: string | null = formConfig.fields[0].ref;

  // Initialize variables
  let variables = formConfig.variables || {};

  while (currentFieldRef) {
    if (visitedFields.has(currentFieldRef)) {
      // Avoid infinite loops
      break;
    }
    visitedFields.add(currentFieldRef);
    fieldRefs.push(currentFieldRef);

    const currentField = formConfig.fields.find(
      (field) => field.ref === currentFieldRef,
    );

    if (!currentField) {
      break; // Field not found
    }

    // Check for logic jump
    const logicJump = formConfig.logic?.find(
      (lj) => lj.type === "field" && lj.ref === currentField.ref,
    );

    let nextFieldRef: string | null = null;
    let logicJumpMet = false;

    if (logicJump) {
      for (const action of logicJump.actions) {
        const conditionMet = evaluateCondition(
          action.condition,
          () => formData,
          variables,
          formContext,
        );

        if (conditionMet) {
          if (action.action === "set") {
            // Handle 'set' action
            const targetVariable = action.details.target.value;
            const value = getConditionVarValue(
              action.details.value,
              () => formData,
              variables,
              formContext,
            );

            variables = {
              ...variables,
              [targetVariable]: value,
            };
          } else if (action.action === "jump" && !logicJumpMet) {
            if (action.details.to.type === "field") {
              nextFieldRef = action.details.to.value;
            } else if (action.details.to.type === "end_screen") {
              // Reached an end_screen, stop traversing fields
              currentFieldRef = null;
              break;
            }
            logicJumpMet = true;
          }
        }
      }
    }

    if (!currentFieldRef) {
      break;
    }

    if (nextFieldRef) {
      currentFieldRef = nextFieldRef;
    } else {
      // Proceed to the next field in formConfig.fields array
      const currentIndex = formConfig.fields.findIndex(
        (field) => field.ref === currentField.ref,
      );
      if (currentIndex < formConfig.fields.length - 1) {
        currentFieldRef = formConfig.fields[currentIndex + 1].ref;
      } else {
        // No more fields
        currentFieldRef = null;
      }
    }
  }

  return fieldRefs;
}
