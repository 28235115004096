import React, { useState } from "react";
import { User } from "firebase/auth";

import { getFriendlyErrorMessage } from "utils/errors";

type CodeEntryProps = {
  phoneNumber: string;
  confirmationResult: any; // Receive confirmationResult via props
  onBack: () => void;
  onComplete?: (user: User) => Promise<void>;
};

const CodeEntry: React.FC<CodeEntryProps> = ({
  phoneNumber,
  confirmationResult,
  onBack,
  onComplete,
}) => {
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleVerifyCode = async () => {
    setErrorMessage("");
    try {
      const result = await confirmationResult.confirm(code);

      // Handle successful sign-in (e.g., redirect)
      if (onComplete) {
        await onComplete(result.user);
      }
    } catch (error: any) {
      console.error("Error verifying code", error);
      setErrorMessage(getFriendlyErrorMessage(error.code));
    }
  };

  return (
    <div className="text-center">
      <button onClick={onBack} className="mb-4 text-blue-500 underline">
        Back
      </button>

      <h3 className="text-center font-serif text-2xl font-bold sm:text-3xl">
        Enter the code we just texted to you
      </h3>
      <p>We sent a code to {phoneNumber}</p>
      <input
        type="text"
        className="mt-4 rounded border border-gray-300 p-2"
        placeholder="Enter code"
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />
      <button
        onClick={handleVerifyCode}
        className="ml-2 mt-4 rounded bg-cantelope p-2 font-bold text-soil"
      >
        Verify Code
      </button>
      {errorMessage && <p className="mt-2 text-red-500">{errorMessage}</p>}
    </div>
  );
};

export default CodeEntry;
