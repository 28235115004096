import React, { ReactNode } from "react";

import {
  DevicePhoneMobileIcon,
  AtSymbolIcon,
} from "@heroicons/react/24/outline";

type ButtonProps = {
  children: ReactNode;
  onClick: () => void;
};

function Button({ children, onClick }: ButtonProps) {
  return (
    <button
      className={`group flex grow cursor-pointer items-center justify-center rounded-md border border-olive bg-white p-4 font-bold text-evergreen outline-none ring ring-inset ring-white hover:bg-ivory sm:grow-0`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

type VerificationMethodSelectionProps = {
  prompt?: string;
  onSelectMethod: (method: "sms" | "email") => void;
};

const VerificationMethodSelection: React.FC<
  VerificationMethodSelectionProps
> = ({ prompt, onSelectMethod }) => {
  return (
    <>
      <div className="mb-8 text-center">
        <h3 className="text-center font-serif text-2xl font-bold sm:text-3xl">
          Verify your account
        </h3>
        {prompt && <p className="mt-2">{prompt}</p>}
      </div>
      <div className="flex w-full flex-col justify-between space-y-8 md:justify-center">
        <div className="flex flex-wrap items-center justify-around gap-6">
          <Button onClick={() => onSelectMethod("sms")}>
            <DevicePhoneMobileIcon className="mr-2 h-6 w-6" />
            Continue with a text
          </Button>
          {/* <Button onClick={() => onSelectMethod("email")}> */}
          {/*   <AtSymbolIcon className="mr-2 h-6 w-6" /> */}
          {/*   Continue with an email */}
          {/* </Button> */}
        </div>
      </div>
    </>
  );
};

export default VerificationMethodSelection;
