import { PRE_NEED_LEAD_SCHEDULE_CONFIG } from "constants/onboarding-pre-need";
import { FAQ } from "constants/plans";
import { useGET } from "hooks/useAPI";
import { useFirebase } from "firebase";
import { OnboardingContainer } from "layouts/OnboardingLayout";
import Header from "components/Header";
import GetInTouch from "components/GetInTouch";
import Accordion from "components/Accordion";

function Skelly() {
  return (
    <div className="my-10 flex items-center justify-center">
      <div className="w-full animate-pulse space-y-6">
        <div className="space-y-4">
          <div className="h-14 w-full rounded bg-gray-200"></div>
          <div className="h-14 w-full rounded bg-gray-200"></div>
          <div className="h-14 w-full rounded bg-gray-200"></div>
        </div>
      </div>
    </div>
  );
}

const PolicyItem = ({ policy }: any) => {
  const {
    insured_full_name,
    plan,
    amount,
    payment_period,
    policy_self_or_other,
    package: {
      config: { name: packageName },
      total_premium,
    },
  } = policy;
  const getStatus = (stage: string) => {
    switch (stage) {
      case "Policy Funded":
        return { label: "Active", color: "fill-green-500" };
      default:
        return { label: "Pending", color: "fill-yellow-400" };
    }
  };
  const status = getStatus(policy.stage);

  return (
    <li className="flex flex-col items-start justify-between space-y-4 py-5 text-left md:flex-row md:items-center md:space-y-0">
      <div className="flex w-full items-center gap-x-4 md:w-1/2">
        <div className="flex flex-col items-start">
          <div className="text-sm">
            {policy_self_or_other === "Myself"
              ? "For yourself"
              : "For your loved one"}
          </div>
          <span className="text-lg font-semibold leading-6">
            {insured_full_name}
          </span>
        </div>
      </div>

      <div className="flex w-full items-start justify-between gap-x-6 md:w-1/2 md:items-center md:justify-end">
        <div>
          <p className="text-sm text-evergreen md:text-right">{packageName}</p>
          <p className="text-evergreen">
            {payment_period !== "single_pay"
              ? `$${total_premium}/mo`
              : `$${total_premium}`}
          </p>
        </div>
        <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
          <svg
            className={`h-1.5 w-1.5 ${status.color}`}
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx="3" cy="3" r="3" />
          </svg>
          {status.label}
        </span>
      </div>
    </li>
  );
};

export default function Home() {
  const { user, loading } = useFirebase();
  const { data, isLoading } = useGET("/user/policies");

  return (
    <OnboardingContainer>
      <div className="absolute -z-10 hidden h-full min-w-full overflow-hidden lg:block">
        <picture>
          <source srcSet="/public/bird-2.webp" type="image/webp" />
          <img
            loading="lazy"
            src="/public/bird-2.png"
            className="absolute -right-24 z-0 opacity-10"
          />
        </picture>
        <picture>
          <source srcSet="/public/bird.webp" type="image/webp" />
          <img
            loading="lazy"
            src="/public/bird.png"
            className="absolute left-0 top-48 z-0 h-48 -scale-x-100 opacity-10"
          />
        </picture>
      </div>

      <div className="w-full px-3 sm:px-4">
        <Header
          properties={{ call_or_schedule: PRE_NEED_LEAD_SCHEDULE_CONFIG }}
        />

        <div className="mx-auto my-16 max-w-xl">
          {loading ? (
            <div className="h-14 w-full rounded bg-gray-200"></div>
          ) : (
            <div className="mb-6 text-center">
              <h1 className="font-serif text-2xl font-bold sm:text-5xl">
                Welcome back{user?.displayName ? `, ${user?.displayName}` : ""}
              </h1>
            </div>
          )}

          <div className="mb-8">
            <h2 className="text-left text-lg font-semibold text-sage">
              Your policies
            </h2>

            {isLoading ? (
              <Skelly />
            ) : (
              <div className="divide-y divide-dashed divide-olive">
                {data.data.length === 0 && (
                  <div className="my-10 text-center">
                    <div className="mb-4 font-serif italic">
                      No policies found
                    </div>

                    <a href="https://www.after.com/prearrange-pricing?utm_source=after_app">
                      <button className="rounded bg-cantelope px-6 py-2 font-bold text-soil transition-all hover:bg-opacity-75">
                        Get started today
                      </button>
                    </a>
                  </div>
                )}

                {data.data.map((policy: any) => (
                  <PolicyItem key={policy.record_id} policy={policy} />
                ))}
              </div>
            )}
          </div>

          <GetInTouch
            properties={{ call_or_schedule: PRE_NEED_LEAD_SCHEDULE_CONFIG }}
          />

          <div className="relative flex h-full min-h-40 w-full flex-col items-center justify-center space-y-8 py-12 text-center sm:mb-12 sm:mt-8 sm:space-y-16">
            <Accordion title="Everying you need to know" items={FAQ} />
          </div>
        </div>
      </div>
    </OnboardingContainer>
  );
}
