import { OnboardingContainer } from "layouts/OnboardingLayout";
import Header from "components/Header";
import EmailVerification from "components/EmailVerification";

export default function EmailVerificationPage() {
  return (
    <OnboardingContainer>
      <div className="w-full px-3 sm:px-4">
        <Header />

        <div className="mx-auto my-16 max-w-xl">
          <EmailVerification />
        </div>
      </div>
    </OnboardingContainer>
  );
}
