import {
  Button,
  Menu,
  MenuItem,
  MenuTrigger,
  Popover,
  Separator,
} from "react-aria-components";
import type { MenuItemProps } from "react-aria-components";
import { Bars3Icon } from "@heroicons/react/24/outline";

import { useFirebase } from "firebase";

function ActionItem(props: MenuItemProps) {
  return (
    <MenuItem
      {...props}
      className="group box-border flex w-full cursor-pointer items-center rounded-md px-3 py-2 text-soil outline-none focus:bg-cantelope-200"
    />
  );
}

export default function UserNav() {
  const { user, loading, logout } = useFirebase();

  if (loading || !user) {
    return null;
  }

  async function handleLogout() {
    await logout();
  }

  return (
    <div className="rounded-lg sm:p-8">
      <MenuTrigger>
        <Button
          aria-label="Menu"
          className="pressed:bg-opacity-40 pressed:bg-black inline-flex items-center justify-center rounded-md border border-white/20 px-3 py-2 text-soil outline-none transition-colors hover:bg-black hover:bg-opacity-20 focus-visible:bg-black focus-visible:bg-opacity-30 focus-visible:ring-2 focus-visible:ring-white/75"
        >
          <Bars3Icon className="h-6 w-6" />
        </Button>
        <Popover className="entering:animate-in entering:fade-in entering:zoom-in-95 exiting:animate-out exiting:fade-out exiting:zoom-out-95 fill-mode-forwards w-56 origin-top-left overflow-auto rounded-md bg-white p-1 shadow-lg ring-1 ring-black ring-opacity-5">
          <Menu className="outline-none">
            <ActionItem onAction={handleLogout}>Logout</ActionItem>
          </Menu>
        </Popover>
      </MenuTrigger>
    </div>
  );
}
