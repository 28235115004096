import React, { useEffect } from "react";
import {
  Controller,
  Control,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { ListBox, ListBoxItem, Key, Selection } from "react-aria-components";
import { CheckIcon } from "@heroicons/react/24/outline";

import { FieldConfig } from "components/Form/types";
import FieldLabel from "./FieldLabel";
import FieldDescription from "./FieldDescription";
import FieldError from "./FieldError";

type YesNoFieldProps = {
  field: FieldConfig;
  control: Control<FieldValues>;
};

const YesNoField: React.FC<YesNoFieldProps> = ({ field, control }) => {
  const isRequired = field.validations?.required || false;

  const { setValue } = useFormContext();

  return (
    <Controller
      control={control}
      name={field.ref}
      rules={{
        // validate function required here to correctly handle the fields boolean value
        validate: (value: boolean) => {
          if (isRequired) {
            return value !== undefined;
          }

          return true;
        },
      }}
      render={({ field: { value }, fieldState: { error } }) => {
        // For single selection, value is Key | undefined
        const typedValue = value as Key | undefined;

        // Build selectedKeys as Iterable<Key> or 'all'
        let selectedKeys: Iterable<Key> | "all" = [];
        if (typedValue !== undefined && typedValue !== null) {
          selectedKeys = [String(typedValue) as Key];
        }

        const onSelectionChange = (keys: Selection) => {
          const newValue = Array.from(keys);

          // For some reason if you re-select the currently active option, the new 'keys' is emptyl
          // and setting the 'selectionBehavior' has no effect.
          if (newValue[0] === undefined) {
            // pass
          } else {
            const value = newValue[0] === "true";

            setValue(field.ref, value, {
              shouldValidate: true,
            });
          }
        };

        return (
          <div className="mb-4">
            <FieldLabel text={field.title} />
            <FieldDescription content={field.properties?.description} />
            <ListBox
              aria-label={field.title}
              selectionMode="single"
              selectionBehavior="replace"
              selectedKeys={selectedKeys}
              onSelectionChange={onSelectionChange}
              className="mt-4 flex flex-col space-y-4 overflow-auto p-1"
            >
              <ListBoxItem
                key="true"
                id="true"
                textValue="Yes"
                className={({ isSelected, isFocused }) =>
                  `group flex cursor-pointer items-center rounded-md border border-olive bg-white p-4 text-evergreen outline-none ring ring-inset ring-white hover:bg-ivory ${isSelected ? "bg-ivory font-bold" : ""} ${
                    isFocused ? "outline-solid outline-2 outline-cantelope" : ""
                  }`
                }
              >
                <div className="mr-4">
                  <div className="h-3 w-3 rounded-full outline outline-2 outline-offset-2 outline-evergreen group-aria-selected:bg-cantelope"></div>
                </div>
                Yes
              </ListBoxItem>
              <ListBoxItem
                key="false"
                id="false"
                textValue="No"
                className={({ isSelected, isFocused }) =>
                  `group flex cursor-pointer items-center rounded-md border border-olive bg-white p-4 text-evergreen outline-none ring ring-inset ring-white hover:bg-ivory ${isSelected ? "bg-ivory font-bold" : ""} ${
                    isFocused ? "outline-solid outline-2 outline-cantelope" : ""
                  }`
                }
              >
                <div className="mr-4">
                  <div className="h-3 w-3 rounded-full outline outline-2 outline-offset-2 outline-evergreen group-aria-selected:bg-cantelope"></div>
                </div>
                No
              </ListBoxItem>
            </ListBox>
            {error && <FieldError>{error.message}</FieldError>}
          </div>
        );
      }}
    />
  );
};

export default YesNoField;
